import React, { Component } from 'react'
import Layout from '../components/layout'
import Button from 'components/CustomButtons/Button.jsx'
import styled from 'styled-components'
import { navigate } from 'gatsby'

const Wrapper = styled.div`
  background-color: #232323;
  display: flex;
  padding: 5% 0;
  flex-direction: column;
  min-height: 100vh;
`

const Heading = styled.h1`
  font-size: 8vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const SubHeading = styled.h3`
  font-size: 2.5vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const Link = styled.button`
  font-size: 2.5vh;
  :hover {
    transition: font-size 1s;
    transition-timing-function: linear;
    font-size: 3vh;
  }
  font-weight: bold;
  color: #ff9000;
  background-color: transparent;
  border: none;
`

const LabelText = styled.h3`
  font-size: 3.5vh;
  font-weight: bold;
  width: 30vw;
  padding: 0 5%;
  color: white;
  margin-right: 2vw;
  @media (max-width: 900px) {
    width: 80vw;
  }
`

const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const LargeInputWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const InputText = styled.input`
  background-color: white;
  width: 40vw;
  height: 3.5vh;
  font-size: 18px;
  font-weight: bold;
  margin-top: 1vh;
  color: #232323;
  padding: 0 1vw;
  @media (max-width: 900px) {
    margin: 0 5%;
    width: 80vw;
  }
`
const TextArea = styled.textarea`
  background-color: white;
  width: 40vw;
  height: 3.5vh;
  font-size: 18px;
  font-weight: bold;
  margin-top: 1vh;
  color: #232323;
  padding: 0.5vh 1vw;
  @media (max-width: 900px) {
    margin: 0 5%;
    width: 80vw;
  }
`

const ButtonWrap = styled.div`
  width: 10vw;
  padding: 3vh 3vw;
  margin: auto;
`

const ButtonText = styled.p`
  font-size: 1.5vh;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  margin: 0;
`

export default class contact extends Component {
  render() {
    return (
      <Layout>
        <Wrapper>
          <Heading>Contact Us</Heading>
          <SubHeading>
            We would love to hear from you – don’t hesitate to get in touch if
            you would like to know more about us!
          </SubHeading>
          <SubHeading>
            Also, be sure to check out our{' '}
            <Link onClick={() => navigate('/faq')}>faq</Link> section.
          </SubHeading>
          <form
            name="contact"
            method="POST"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />

            <InputWrap style={{ marginTop: '3vh' }}>
              <LabelText style={{ marginTop: '3vh' }}>Name:</LabelText>
              <InputText type="text" name="name" placeholder="Name" />
            </InputWrap>
            <InputWrap>
              <LabelText style={{ marginTop: '3vh' }}>Email:</LabelText>
              <InputText name="email" type="email" placeholder="E-Mail" />
            </InputWrap>
            <InputWrap>
              <LabelText style={{ marginTop: '3vh' }}>Telephone:</LabelText>
              <InputText name="phone" type="text" placeholder="Phone" />
            </InputWrap>
            <LargeInputWrap>
              <LabelText style={{ marginTop: '3vh' }}>Message:</LabelText>
              <TextArea
                rows={10}
                placeholder="Your message goes here."
                defaultValue={''}
                style={{ height: '25vh' }}
              />
            </LargeInputWrap>
            <ButtonWrap>
              <Button type="submit" round color="warning" size="lg">
                <ButtonText>Submit</ButtonText>
              </Button>
            </ButtonWrap>
          </form>
        </Wrapper>
      </Layout>
    )
  }
}
